import React from "react";
import {Link} from "react-router-dom";
const MainComp = () =>
{
 
    return <>
                <div className="MainComp fitt">
                    <center className="fitt">
                        <h1 className="title has-text-white is-1 mb-2">Main Menu</h1>
                        <div className="box" style={{"width" : "60%","height" : "60%"}}>
                        <aside claclassNamess="menu">
                            <p className="menu-label">
                                Routes
                            </p>
                            <ul className="menu-list">
                                <Link to="/projectId/Overview">Overview</Link>
                                <Link to="/projectId/QC">Quality Control</Link>
                            </ul>
                            </aside>
                        </div>
                    </center>
                </div>
           </>;
}
export default MainComp;
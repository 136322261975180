import React, { useState } from "react";
import { ApiCall, originFolder, version } from "../Data/Variables";
import {useParams} from "react-router-dom";
import { pythonUrl } from "../Data/Variables"; 
import { useCookies } from "react-cookie";

const DownloadButton = ({checkNumber,projectId,setButtonOkLoad}) =>
{
    const [cookies,setCookies,] = useCookies(["jwt"]);

    const downloadFile = async () =>
    {
        var params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
            body: new URLSearchParams({"data_directory" : originFolder + projectId+"/","checkNumber" : checkNumber })
        }
        
        var blob = await ApiCall(pythonUrl+"/downloadQC",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setButtonOkLoad(false);
        });

        blob = await blob.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Table.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setButtonOkLoad(false);
    }

    return <button className="button is-info is-light" onClick={(e) => downloadFile()}>Download Table</button>

}
const MakeTable = ({tableData,projectId,setButtonOkLoad}) =>
{
    var tr = tableData;
    var trAry = [];
    for(var y=0;y<tr.length;y++)
    {
        var tdAry = [];
        for(var x = 0;x< 4 ;x++)
        {  
            if(x === 1)
                tdAry.push(<React.Fragment key={y*tr.length+x}><td> { tr[y][x] ? 
                    <span className="tag icon is-small is-success"><i className="fa-solid fa-check"/></span>
            : <span className="tag icon is-small is-danger"><i className="fa-solid fa-x"/></span>}</td></React.Fragment>);
            else if(x === 2)
                tdAry.push(<React.Fragment key={y*tr.length+x}><td>{tr[y][x].map((val,index) => 
                    <React.Fragment key={index}><span>{val}</span><br/></React.Fragment>)}</td></React.Fragment>);
            else if(x === 3)
            {
                if(!tr[y][1])
                    tdAry.push(<React.Fragment key={y*tr.length+x}><td><DownloadButton checkNumber={y} projectId={projectId} setButtonOkLoad={setButtonOkLoad}/></td></React.Fragment>);
            }
            else
                tdAry.push(<React.Fragment key={y*tr.length+x}><td> {tr[y][x]}</td></React.Fragment>);
        }
        trAry.push(<React.Fragment key={y}><tr>{tdAry}</tr></React.Fragment>)
    }
    return(
        <table className="table is-hoverable is-scrollable is-gapless is-striped is-narrow" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0"}}>
            <thead>
                <tr>
                    <th className="title is-6">{"Check Number"}</th>
                    <th className="title is-6">{"Status"}</th>
                    <th className="title is-6">{"details"}</th>
                    <th className="title is-6">{"Download Table"}</th>
                </tr>
            </thead>
            <tbody>
                {trAry}
            </tbody>
        </table>
    );
}
const QcComp = () =>
{
    let { projectId } = useParams();
    const [buttonOkLoad,setButtonOkLoad] = useState(false);
    const [tableComp,setTableComp] = useState(<></>);
    const [cookies,setCookies,] = useCookies(["jwt"]);
    console.log( cookies.jwt);
    const QcCheck = async() =>
    {
        setButtonOkLoad(true);

        var params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
            body: new URLSearchParams({"data_directory" : originFolder + projectId+"/" })
        }
        
        var res = await ApiCall(pythonUrl+"/QualityCheck",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setButtonOkLoad(false);
        });
        res = await res.json();
        res = res["data"];
        var tableAry =[];
        for(var i=0;i<res.length;i++)
        {
            tableAry.push(<React.Fragment key={i}><MakeTable tableData={res[i]} projectId={projectId} setButtonOkLoad={setButtonOkLoad}/></React.Fragment>);
        }
        setTableComp(<div className="table-container box" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0","height" : "88%"}}>{tableAry}</div>);
        setButtonOkLoad(false);
    }
    return (<>
                <center><span className="tag is-info is-light mt-3">{version}</span></center>
                <div className="MainComp fitt">
                    <center className="fitt">
                        <div className="box" style={{"width" : "90%","height" : "80%"}}>
                            <div className="buttons is-centered">
                                <button className={"button is-success is-light" + (buttonOkLoad ? " is-loading" : "")} disabled={projectId === "projectId" || projectId === ""}  onClick={() => QcCheck()}>Run QC check</button>
                            </div>
                            {tableComp}
                        </div>
                    </center>
                </div>
            </>);
}
export default QcComp;
var Nodeurl = "";
var originFolder = "";
var pythonUrl = "";
var currUrl = "";
var authURL = "";
if (window.location.href.includes("localhost")) {
    originFolder = "C:/Users/Gad/Documents/GitHub/try/Files/";
    Nodeurl = "http://localhost:8090"
    pythonUrl = "http://127.0.0.1:5100";
    currUrl = "http://localhost:3003"
    authURL = "http://localhost:3001"
}
else 
{
    originFolder = "/mnt/disks/birddata2/birddata/";
    Nodeurl      = "https://api.bademeister-jan.pro:453"
    pythonUrl    = "https://api.bademeister-jan.pro:336";
    currUrl = "https://preview.bitsonic.ai/"
    authURL = "https://auth.bitsonic.ai/"

}
var version = "V0.1008"
                        //#0                  1                 2              3        4           5             6
var titleColumns = ["Catalogue Name" , "Catalogue Date" , "Last Modified","Payor","Accounts", "Line Count","Currency",
                        //      7           8               9          10         11               12               13
                    "Nominal Rev", "Adjusted ($)", "Adjusted ","Files","Number Of Songs","Total Revenue($)","Total"]

const ApiCall = async (apicall,parameters,setCookie,raiseError= (err)=>{console.log(err)}) =>
{
    var res = undefined;
    await fetch(
        apicall,
        parameters
    )
    .then((data) =>
    {
        if (data.status === 401)
        {
            console.log(authURL);   
            setCookie("redirection",window.location.href,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost","path" : "/"});
            alert("re-login required");
            window.location.href = authURL; 
        }
        else
        {
            const dataHeaders = Object.fromEntries(data.headers);
            if(dataHeaders.jwt !== undefined)
                setCookie("jwt",dataHeaders.jwt,{"domain" : window.location.href.includes("bitsonic") ? ".bitsonic.ai" : "localhost","path" : "/"});
            res = data;
        }
    }).catch((err) => raiseError(err));
    return res;
}

export {Nodeurl,originFolder,pythonUrl,version,titleColumns,ApiCall};
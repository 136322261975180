import React, { useState } from "react";
import { pythonUrl,originFolder, version, titleColumns, ApiCall } from "../Data/Variables";
import {useParams} from "react-router-dom";
import { useCookies } from 'react-cookie';

let OVERVIEW_TITLE = ["Output Summary","Statement Catalogue Summary","Cash Date Catalogue Summary","Payor Summary","Payor Account Summary"]

const MakeTable = ({tableData,index}) =>
{
    var th = Object.keys(tableData);
    var tr = Object.values(tableData);
    var thAry = [];
    var trAry = [];
    for(var i=0;i<th.length;i++)
    {
        if(th[i] !== "Number Of Songs")
            thAry.push(<React.Fragment key={i}><th className="title is-6">{th[i]}</th></React.Fragment>);
    }
    var tagClass = "";
    let isOverview = OVERVIEW_TITLE.includes(tr[0][0]);
    for(var y=isOverview ? 1 : 0;y<tr[0].length;y++)
    {
        var tdAry = [];
        for(var x = 0;x<tr.length;x++)
        {  
            if(th[x] !== "Number Of Songs")
            {
                tr[x][y] = tr[x][y]+"";
                // eslint-disable-next-line no-useless-escape
                var match = (tr[x][y]).match(/[\d+\,\.\%\-]+/)
                var columnsAry =[index === 2 ? titleColumns[4] : "",titleColumns[1],titleColumns[0],titleColumns[2],titleColumns[3]]
                match = match !== null && !columnsAry.includes(th[x])

                tagClass  = (th[x] === "Currency" ? "tag is-primary is-light" : (th[x] === "Payor" ? "tag is-info is-light" : ""));
                tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={match ? "has-text-right" : "has-text-left"}><span className={tagClass}>{tr[x][y]}</span></td></React.Fragment>);
            }
        }
        trAry.push(<React.Fragment key={y}><tr>{tdAry}</tr></React.Fragment>)
    }
    if(isOverview)
        thAry = <React.Fragment key={thAry.length}>
                    <tr className='title is-5'>
                        <td className='has-text-centered' colSpan='999'>
                            {tr[0][0]}
                        </td>
                    </tr>
                    <tr>
                        {thAry}
                    </tr>
                </React.Fragment>
    return(
        <>
        <table className="table is-hoverable is-scrollable is-gapless is-striped is-narrow" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0"}}>
            <thead>
                {thAry}
            </thead>
            <tbody>
                {trAry}
            </tbody>
        </table>
        </>
    );
}
const downloadOverview = async (cookies,projectId,setButtonOkLoad,setCookies) =>
{
    var params = 
    {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
        body: new URLSearchParams({"data_directory" : originFolder + projectId+"/"})
    }

    var blob = await ApiCall(pythonUrl+"/downloadOverview",params,setCookies,(err) => 
    {
        console.log(err)
        alert("Data Error. Please contact us at data@bitsonic.ai");
        setButtonOkLoad(false);
    });

    blob = await blob.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Table.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
    setButtonOkLoad(false);
    
}
const OverviewComp = () =>
{
    const [buttonOkLoad,setButtonOkLoad] = useState(false);
    const [tableComp,setTableComp] = useState([]);
    let { projectId } = useParams();
    const [cookies,setCookies,] = useCookies(["jwt","redirection"]);
    const generateOverview = async () =>
    {
        setButtonOkLoad(true);
        var details = {}
        await fetch("https://api.bademeister-jan.pro/outputs/catalogs",{method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({"projectids" : '["' +projectId+ '"]'}),})
        .then(res => res.json())
        .then(resp => 
        {
            details = resp["result"][0]
            details["currency"]["usd"] = 1;
        })
        var params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
            body: new URLSearchParams(
            {
                "catalog_id" : projectId,
                "returnValue" : "true",
                "isQC" : 'true',
                "currencyKey" : details["outputCurrency"],
                "outputAry" : '["overview"]',
                "yearParam" : "NormalData"
            })
        }
        var res = await ApiCall(pythonUrl+"/GenerateTabOutput",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setButtonOkLoad(false);
        });
        res = await res.json();
        console.log(res)
        if("failed" in res)
        {
            
            var trAry = [];
            for(var i=0;i<res["failed"][1].length;i++)
            {
                trAry.push(<React.Fragment key={i}><tr><td>{res["failed"][1][i]}</td><td>{res["failed"][2][i]}</td></tr></React.Fragment>)
            }
            setTableComp(
            <div className="table-container box" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0","height" : "88%"}}>
                
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Payor</th>
                            <th>File</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trAry}
                    </tbody>
                </table>
            </div>);
            alert(res["failed"][0]);
            setButtonOkLoad(false);
        }
        else
        {
            console.log(res);
            res = res["data"];
            let tableAry =[];
            for(let i=0;i<res.length;i++)
            {
                tableAry.push(<React.Fragment key={i}><MakeTable tableData={res[i]} index={i}/></React.Fragment>);
            }
            setTableComp(<><center><button className="button mb-2 is-primary is-light" onClick={() => downloadOverview(cookies,projectId,setButtonOkLoad,setCookies)}>Download Sheet</button></center><div className="table-container box" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0","height" : "88%"}}>{tableAry}</div></>);
            setButtonOkLoad(false);
        }
   
    }
    
    return (<>
                <center><span className="tag is-info is-light mt-3">{version}</span></center>
                <div className="MainComp fitt">
                    <div className="box centered" style={{"width" : "90%","height" : "80%"}}>
                        <div className="buttons is-centered">
                            <button className={"button is-link is-light " + (buttonOkLoad ? "is-loading" : "")} title='Change project id in url' disabled={projectId === "projectId" || projectId === ""} onClick={() => generateOverview()}>Generate Overview</button>
                        </div>
                        {tableComp}
                    </div>
                </div>
            </>);
}
export default OverviewComp;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from "./redux/store";
import './index.css';
import 'bulma/css/bulma.min.css';
import {Provider } from "react-redux";
import axios from 'axios';
import './index.scss'
import QualityControl  from './Components/QC_Component'
import OverView  from './Components/OverviewComponent'
import NoPage  from './Components/NoPage'
import MainComp from './Components/MainComponent';
function getCookie(
    cname
){
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}


function setCookie(
    cname,
    cvalue,
    exdays
) {
    let dt, expires;
    dt = new Date();
    dt.setTime(dt.getTime()+(exdays*24*60*60*1000));
    expires = "; expires="+dt.toUTCString();

    document.cookie = cname+"="+cvalue+expires+'; domain=' + process.env.REACT_APP_WEBSITES_DOMAIN;
}


export default class LoginCheck extends React.Component{
    async componentDidMount() {
        axios.post(
            `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/authorize`,
            {

            },
            {
                headers: {
                    Authorization: `Bearer ${getCookie('jwt')}`
                }
            }
        )
            .then(async (response) => {
                // user is authorized (jwt cookie is valid)
                const result = await response.data;

                if (this.props.signInFunction !== undefined) {
                    this.props.signInFunction({
                        name: result.name,
                        picture: result.picture,
                        authorized_urls: result.authorized_urls
                    });
                }

                if (this.props.onReady !== undefined){
                    this.props.onReady();
                }
            })
            .catch((error) => {
                // error code can be found at: error.response.status;

                if (window.location.hostname !== process.env.REACT_APP_AUTHENTICATION_WEBSITE_HOSTNAME) {
                    // redirect to the login website
                    setCookie(
                        'redirection',
                        window.location.href,
                        365 * 999
                    );

                    window.location.href = `https://${process.env.REACT_APP_AUTHENTICATION_WEBSITE_HOSTNAME}/`
                }
            });
    }

    render() {
        return null;
    }
}
const MainPage = () =>
{
    return  (
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route path="/"  element={<MainComp />} />
                    <Route path="/:projectId/Overview"  element={<OverView />} />
                    <Route path="/:projectId/QC" element={<QualityControl />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
      {/*<LoginCheck />*/}
    </>
   );
}

ReactDOM.render(
    <Provider store={store}>
      <MainPage />
    </Provider>,
  document.getElementById('root')
);
